import React, { useState } from 'react';
import { auth } from '../firebase/firebase-config';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import LOGO_white from '../data/LOGO-white.png';

const Signup = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Access the user information (optional)
      const user = userCredential.user;
      console.log('User created:', user);

      // Clear form fields
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setError(null);

      // Redirect to the login page after successful signup
      navigate('/');
    } catch (error) {
      // Handle registration errors
      setError(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-top min-h-screen">
      <img
        className="squared-full h-24 w-24 fill-slate-50 mt-8"
        src={LOGO_white} 
        alt="LOGO"
      />
      <p className="text-white font-bold text-4xl mt-2">Welcome to LPRS</p>
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="w-400 bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">First Name</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={`${inputStyle}`}
            />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">Last Name</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className={`${inputStyle}`}
            />
          </div>
          <div className="mb-4">
            <label className="block text-white text-sm font-bold mb-2">E-Mail</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`${inputStyle}`}
            />
          </div>
          <div className="mb-6">
            <label className="block text-white text-sm font-bold mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={`${inputStyle}`}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              //onClick={handleRegister}
              className={`${buttonStyle}`}
            >
              Sign Up!
            </button>
            <Link to="/" className={`${linkStyle}`}>
              Back to Login
            </Link>
          </div>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </div>
      </div>
    </div>
  );
};

// You can define these styles as part of your global styles or use a utility-first CSS library like Tailwind CSS
const inputStyle = `
  shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
`;

const buttonStyle = `
  bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
`;

const linkStyle = `
  text-blue-500 hover:underline
`;

export default Signup;
