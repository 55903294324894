import React, { useState } from 'react';
import { auth } from '../firebase/firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { useStateContext } from '../contexts/ContextProvider';
import LOGO_white from '../data/LOGO-white.png';

const Login = ({ setUserIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { currentColor } = useStateContext();

  const handleLogin = async () => {
    try {
      // Sign in user with email and password
      await signInWithEmailAndPassword(auth, email, password);

      // Set userIsLoggedIn to true using the prop
      setUserIsLoggedIn(true);

      // Clear form fields
      setEmail('');
      setPassword('');
      setError(null);
    } catch (error) {
      // Handle login errors
      setError('Invalid E-Mail or Password');
    }
  };

  document.body.style.backgroundColor = '#20232A'; 
  document.body.style.margin = '0'; 
  document.body.style.padding = '0'; 

  return (
    <div className="flex flex-col items-center justify-top min-h-screen">
      <img
        className="squared-full h-24 w-24 fill-slate-50 mt-8"
        src={LOGO_white}
        alt="LOGO"
      />
      <p className="text-white font-bold text-4xl mt-2">Welcome to LPRS</p>  
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className={`w-400 bg-secondary-dark-bg rounded-3xl p-6 m-3`}>
        <div className="flex justify-between">
            <p className="font-bold text-xl text-white">Login:</p>
          </div>
          <div className="mt-10">
            <div className="mb-4">
              <label className="block text-white text-sm font-bold mb-2">E-Mail</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${inputStyle}`}
              />
            </div>
            <div className="mb-6">
              <label className="block text-white text-sm font-bold mb-2">Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`${inputStyle}`}
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                onClick={handleLogin}
                className={`${buttonStyle}`}
              >
                Login
              </button>
              <Link to="/Signup" className={`${linkStyle}`}>
                No account yet? Signup now!
              </Link>
            </div>
            {error && <p className="text-white mt-4">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

// You can define these styles as part of your global styles or use a utility-first CSS library like Tailwind CSS
const inputStyle = `
  shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline
`;

const buttonStyle = `
  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline
`;

const linkStyle = `
  text-blue-500 hover:underline
`;

export default Login;
