import React, { useState, useEffect } from "react";
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Toolbar, Edit, Sort, Filter, Inject } from "@syncfusion/ej2-react-grids";
import { db } from "../firebase/firebase-config";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { useStateContext } from '../contexts/ContextProvider';


const Users = () => {
  const [newName, setNewName] = useState("");
  const [newLicensePlate, setNewLicensePlate] = useState("");
  const [newTelegramID, setNewTelegramID] = useState("");
  const [newEMailAdress, setNewEMailAdress] = useState("");
  const [newAge, setNewAge] = useState(0);
  const { currentColor } = useStateContext();
  const [users, setUsers] = useState([]);
  const usersCollectionRef = collection(db, "userData");

  const createUser = async () => {
    await addDoc(usersCollectionRef, {
      name: newName,
      age: Number(newAge),
      licensePlate: newLicensePlate,
      telegramid: newTelegramID,
      emailAdress: newEMailAdress,
    });

    // Clear input fields after creating user
    setNewName("");
    setNewAge("");
    setNewLicensePlate("");
    setNewTelegramID("");
    setNewEMailAdress("");
  };

  const updateUser = async (args) => {
    if (!args.data || !args.data.id) {
      console.error("Invalid data or data.id is undefined", args.data);
      return;
    }

    const userDoc = doc(db, "userData", args.data.id);


    const updatedFields = {
      name: args.data.name,
      age: args.data.age,
      licensePlate: args.data.licensePlate,
      telegramid: args.data.telegramid,
      emailAdress: args.data.emailAdress,
    };

    await updateDoc(userDoc, updatedFields);
  };

  const deleteUser = async (id) => {
    const userDoc = doc(db, "userData", id);
    await deleteDoc(userDoc);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(usersCollectionRef, (snapshot) => {
      setUsers(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    });

    // Cleanup function to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array to run the effect only once

  // Mapping der Firebase Daten um sie mit dem Syncfusion-Grid Kompatibel zu machen
  const gridData = users.map((user) => ({
    id: user.id,
    name: user.name,
    age: user.age,
    licensePlate: user.licensePlate,
    emailAdress: user.emailAdress,
    telegramid: user.telegramid,
  }));

  // Definition der Spalten
  const gridColumns = [
    { field: "name", headerText: "Name", isPrimaryKey: true },
    { field: "age", headerText: "Age" },
    { field: "licensePlate", headerText: "License Plate" },
    { field: "emailAdress", headerText: "Email Address" },
    { field: "telegramid", headerText: "Telegram ID" },
  ];

  // Einstellungen im Syncfusion Grid
  const editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true };

  return (
    <div className="flex flex-col items-center">
      <div className="w-400 mb-4 p-4 bg-white dark:bg-secondary-dark-bg rounded-md">
        <h1 className="font-bold text-xl mb-4 text-white">Data Entry</h1>
        <div className="grid grid-cols-2 gap-4">
          <input
            placeholder="Name..."
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
            className="rounded-md p-2 border"
          />
          <input
            type="number"
            placeholder="Age..."
            value={newAge}
            onChange={(event) => setNewAge(event.target.value)}
            className="rounded-md p-2 border"
          />
          <input
            placeholder="License Plate..."
            value={newLicensePlate}
            onChange={(event) => setNewLicensePlate(event.target.value)}
            className="rounded-md p-2 border"
          />
          <input
            placeholder="E-Mail..."
            value={newEMailAdress}
            onChange={(event) => setNewEMailAdress(event.target.value)}
            className="rounded-md p-2 border"
          />
          <input
            placeholder="Telegram ID..."
            value={newTelegramID}
            onChange={(event) => setNewTelegramID(event.target.value)}
            className="rounded-md p-2 border"
          />
        </div>
        <button
         onClick={createUser}
         className="bg-blue-500 text-white rounded-md p-2 mt-4 justify-center"
        >
            Create User
         </button>
      </div>
      <div className="w-600 mt-4 p-4 bg-white dark:bg-secondary-dark-bg rounded-md">
        <h1 className="font-bold text-xl mb-4 text-white">User Data</h1>
        <GridComponent
          dataSource={gridData}
          allowPaging={true}
          pageSettings={{ pageCount: 5 }}
          editSettings={{ allowEditing: true, allowAdding: true, allowDeleting: true }}
          actionBegin={updateUser}
        >
          <ColumnsDirective>
            {gridColumns.map((column, index) => (
              <ColumnDirective key={index} {...column} />
            ))}
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit, Sort, Filter]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default Users;
