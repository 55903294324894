import { initializeApp } from "firebase/app";
import {getAuth,} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCbbSfWsXA1nANRga5gFex1uu_nNecdaYk",
  authDomain: "test-60ca4.firebaseapp.com",
  projectId: "test-60ca4",
  storageBucket: "test-60ca4.appspot.com",
  messagingSenderId: "1071430788988",
  appId: "1:1071430788988:web:4690e062c3950ca28026f9",
  measurementId: "G-YLD66MY5ZL"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);






