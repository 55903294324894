import React, { useState, useEffect } from 'react';
import { IoIosMore } from 'react-icons/io';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase/firebase-config';
import { useStateContext } from '../contexts/ContextProvider';

const LiveCam = () => {
  const [currentLicensePlate, setCurrentLicensePlate] = useState('');
  const [currentAccessStatus, setCurrentAccessStatus] = useState('');
  const [images, setImages] = useState([]);
  const currentLPRef = collection(db, 'currentLP');
  const currentAccessStatusRef = collection(db, 'gateStatus');
  const { currentColor } = useStateContext();

  useEffect(() => {
    const unsubscribeLP = onSnapshot(currentLPRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        setCurrentLicensePlate(doc.data().currentLicensePlateNumber || '');
      });
    });

    const unsubscribeAccessStatus = onSnapshot(currentAccessStatusRef, (snapshot) => {
      snapshot.docs.forEach((doc) => {
        setCurrentAccessStatus(doc.data().currentGateStatus || '');
      });
    });

    // Cleanup functions to unsubscribe when the component unmounts
    return () => {
      unsubscribeLP();
      unsubscribeAccessStatus();
    };
  }, []);

  // Update background color based on currentAccessStatus

    console.log('Current Access Status:', currentAccessStatus);
    console.log('Current LP:', currentLicensePlate); 



  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center">
        <div className="w-400 bg-white dark:bg-secondary-dark-bg rounded-2xl p-6 m-3">
          <div className="flex justify-between">
            <p className="font-bold text-xl text-white">Live-Cam</p>
            <button type="button" className="text-xl font-semibold text-gray-500">
              <IoIosMore />
            </button>
          </div>
          <div className="mt-10">
            <div className="flex justify-center">
              {images.map((image, index) => (
                <img key={index} className="md:w-96 h-50" src={image.path_display} alt={image.name} />
              ))}
            </div>
            <div className="mt-8">
              <p className="font-semibold text-lg text-gray-400">Live-Cam coming soon!</p>
              <p className="text-gray-400">Comment written by Laurenz Danninger</p>
              <p className="mt-8 text-sm text-gray-400">
                The LiveCam feature, as the name suggests, will include live footage of your parking space. This will soon be implemented.
              </p>
              <div className="mt-3">
                <input
                  type="text"
                  value={currentLicensePlate}
                  placeholder="Current License Plate..."
                  style={{
                    color: 'white',
                    background: currentColor,
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    width: '100%',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '8px',
                  }}
                  readOnly
                />
              </div>
              <div className="mt-3">
                <input
                  type="text"
                  value={"Gate is currently "+currentAccessStatus}
                  placeholder="Current Access Status..."
                  style={{
                    color: 'white',
                    background: currentColor,
                    textAlign: 'center',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    width: '100%',
                    padding: '10px',
                    border: 'none',
                    borderRadius: '8px',
                  }}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveCam;
